.client-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  height: 170px;
  transition: filter .3s ease-in-out;
  filter: contrast(.3) saturate(0) brightness(1.4);
  justify-items: center;

  img {
    position: relative;
    top: 10px;
    display: block;
    width: 134px;
    height: 120px;
    transition: top .3s ease-in-out, transform .3s ease-in-out;
    transform: scale(0.9);
    object-fit: contain;
  }

  &__title {
    position: relative;
    bottom: 25px;
    font-weight: 400;
    line-height: 22px;
    transition: opacity, bottom;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    text-align: center;
    opacity: 0;
  }

  &:hover,
  &.swiper-slide-active {
    opacity: 1;
    filter: contrast(1) saturate(1) brightness(1);

    img {
      top: 0;
      transform: scale(1);
    }

    .client-item__title {
      bottom: 0;
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .client-item {
    width: 210px;
  }
}

@media only screen and (max-width: 1080px) {
  .client-item {
    width: 230px;
  }
}

@media only screen and (max-width: 950px) {
  .client-item {
    width: 210px;
  }
}