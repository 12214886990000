.cta {
  background-color: #eeeff4;

  .container {
    padding-top: 45px;
    padding-bottom: 50px;
    display: flex;
    gap: 35px 20px;
    justify-content: space-between;
  }

  .text-title {
    max-width: 265px;
  }

  .form {
    width: fit-content;

    .input-label {
      align-items: initial;
    }

    .input-label svg {
      width: 20px;
      height: 20px;
    }

    .input-label::after {
      font-weight: 500;
      color: #000000;
      background-color: #000000;
    }

    &__body {
      margin-bottom: 19px;
      display: flex;
      gap: 30px;
    }

    &__inputs {
      padding-top: 0;
      flex-direction: row;
      align-items: end;
      gap: 30px;
    }

    .privacy {
      color: #a8a8a8;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .cta {
    .container {
      flex-direction: column;
    }

    .text-title {
      max-width: initial;

      br {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .cta {
    .form {
      .input-label {
        width: 100%;
      }

      &__body {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .cta {
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .form {
      &__inputs {
        flex-direction: column;
      }
    }
  }
}