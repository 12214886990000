.subtitle {
  font-size: 40px;
  font-weight: 400;
  line-height: initial;
}

@media only screen and (max-width: 1400px) {
  .subtitle {
    font-size: 33px;
  }
}

@media only screen and (max-width: 1060px) {
  .subtitle {
    font-size: 26px;
  }
}

@media only screen and (max-width: 800px) {
  .subtitle {
    font-size: 20px;
  }
}