.contacts-fixed {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .contacts-fixed {
    display: flex;
  }
}