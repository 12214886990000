@use '../vars' as v;

.btn {
  padding: 15.5px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 184px;
  transition: background-color .3s ease-in-out;
  color: #ffffff;
  background-color: v.$violet;

  &:hover {
    background-color: #6267fd;
  }

  &:active {
    background-color: #272a88;
  }
}

@media only screen and (max-width: 500px) {
  .btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}