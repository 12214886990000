@use 'src/scss/vars';

html {
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *::after, *::before {
  box-sizing: inherit;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1202px;
}

.section {
  margin: 120px auto;
  padding-top: 50px;
}

.main-wrapper {
  display: flex;
  flex: 0 0;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  font-family: 'Underground', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: vars.$dark-blue;
}

.disable-scroll {
  overflow: hidden;

  body {
    padding-right: 17px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1000px) {
  .section {
    margin: 85px auto;
    padding-top: 35px;
  }
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .section {
    margin: 60px auto;
    padding-top: 30px;
  }
  .disable-scroll {
    body {
      padding-right: 0;
    }
  }
}