.dot-underline {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: url('data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' stroke=\'%231C1F33FF\' stroke-width=\'1\' stroke-dasharray=\'2%2c 4\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e');
  }
}