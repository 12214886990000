.stages {
  &__body-wrapper {
    display: flex;
    gap: 45px;
    justify-content: space-between;
  }

  &__img-wrapper {
    width: 100%;
    max-width: 369px;
    height: 912px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    width: 100%;
    max-width: 702px;
  }

  .section-title {
    margin-bottom: 40px;
    max-width: 470px;
    line-height: 115%;
  }

  &__text {
    margin-bottom: 60px;
    max-width: 586px;
    line-height: initial;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1050px) {
  .stages {
    &__img-wrapper {
      height: 800px;
    }

    .section-title {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .stages {
    &__img-wrapper {
      display: none;
    }
  }
}