.titled-block {
  padding-left: calc(var(--imgSize) + var(--gap));
  --imgSize: 82px;
  --gap: 34px;

  &__title-wrapper {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: calc((var(--imgSize) + var(--gap)) * -1);
      display: block;
      width: var(--imgSize);
      height: var(--imgSize);
      object-fit: contain;
    }

    .text-bold {
      margin-bottom: 14px;
    }
  }

  &__text {
    p + * {
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 1015px) {
  .titled-block {
    --imgSize: 65px;
    --gap: 20px;

    .text-bold {
      margin-bottom: 9px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .titled-block {
    padding-left: 0;

    &__title-wrapper {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        position: initial;
      }

      .text-bold {
        margin-bottom: 0;
      }
    }
  }
}