@use '../vars' as v;

.task {
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 357px;
  overflow: hidden;
  cursor: pointer;
  color: #ffffff;
  background-color: v.$violet;

  &__title {
    font-size: 32px;
    font-weight: 800;
    line-height: 118%;
    transition: transform .35s ease-out;
    transform-origin: top left;
  }

  &__img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: bottom right;

    img {
      position: absolute;
      transition: transform .35s ease-out;
      object-fit: contain;
    }
  }

  &__img {
    z-index: 1;
    right: 0;
    bottom: 0;
  }

  &__img-bg {
  }

  .link-btn {
    margin-top: auto;
    transition: transform .35s ease-out;
    transform-origin: 0 100%;

    &::after {
      transition: transform .35s ease-out;
      transform-origin: 65% 90%;
    }
  }

  &:hover {
    .task__title {
      transform: scale(1.1);
    }

    .link-btn {
      transform: scale(1.15);

      &::after {
        transform: scale(1.85);
      }
    }
  }
}

// First task item - "Онлайн паспорт..."
.task:nth-child(1) {
  .task__title {
    max-width: 375px;
  }

  .task__img {
    bottom: 15px;
    transform-origin: 100% 100%;
  }

  .task__img-bg {
    right: 8px;
    bottom: -27px;
    width: 370px;
    height: 270px;
  }

  &:hover {
    .task__img {
      transform: scale(1.07);
    }

    .task__img-bg {
      transform: scaleY(0.86) scaleX(0.91);
    }
  }
}

// Seconds task item - "Обучающий портал для..."
.task:nth-child(2) {
  background-color: v.$light-violet;

  .task__title {
    max-width: 320px;
  }

  .task__img {
    transform-origin: bottom;
  }

  .task__img-bg {
    right: -9px;
    bottom: -57px;
    width: 395px;
    height: 317px;
  }

  &:hover {
    .task__img {
      transform: scale(1.08);
    }

    .task__img-bg {
      transform: scale(0.9);
    }
  }
}

// Third task item - "Маркетплейс для юр..."
.task:nth-child(3) {
  background-color: #642eff;

  .task__title {
    max-width: 370px;
  }

  .task__img {
    right: 25px;
    transform-origin: bottom right;
  }

  .task__img-bg {
    right: 15px;
    bottom: -208px;
    width: 360px;
    height: 413px;
  }

  &:hover {
    .task__img {
      transform: scale(1.1);
    }

    .task__img-bg {
      transform: scale(0.9) scaleY(1.1);
    }
  }
}

// Fourth task item - "Веб сервис и мобильное приложение..."
.task:nth-child(4) {
  background-color: #283ade;

  .task__img {
    &:nth-child(1) {
      right: 20px;
      width: 264px;
      height: 209px;
      transform-origin: bottom right;
    }

    &:nth-child(2) {
      right: 285px;
      bottom: 15px;
    }
  }

  .task__img-bg {
    right: -46px;
    bottom: -46px;
    width: 412px;
    height: 250px;
  }

  &:hover {
    .task__img:nth-child(1) {
      transform: scale(1.1);
    }

    .task__img-bg {
      transform: scaleX(0.8) scaleY(0.98);
    }
  }
}

@media only screen and (max-width: 1400px) {
  .task {
    height: 270px;

    &__title {
      font-size: 22px;
    }

    &:nth-child(1) &__title,
    &:nth-child(2) &__title,
    &:nth-child(3) &__title {
      max-width: 260px;
    }

    &:nth-child(4) &__title {
      max-width: 270px;
    }

    &__img-wrapper {
      transform: scale(0.76);
    }
  }
}

@media only screen and (max-width: 950px) {
  .task {
    padding: 20px;
    height: 210px;

    &__title {
      font-size: 19px;
    }

    &:nth-child(1) &__title {
      max-width: 220px;
    }

    &:nth-child(2) &__title,
    &:nth-child(3) &__title,
    &:nth-child(4) &__title {
      max-width: 230px;
    }

    &__img-wrapper {
      transform: scale(0.55);
    }
  }
}

@media only screen and (max-width: 760px) {
  .task {
    padding: 15px;
    height: 170px;

    &__title {
      font-size: 16px;
    }

    &:nth-child(1) &__title,
    &:nth-child(2) &__title,
    &:nth-child(3) &__title,
    &:nth-child(4) &__title {
      max-width: 195px;
    }

    &__img-wrapper {
      transform: scale(0.45);
    }

    &:hover {
      .link-btn::after {
        transform: scale(1.55);
      }
    }
  }
}