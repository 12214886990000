.triangle-drawer {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/static/pattern.png');
  background-position: left top;

  svg {
    width: 100%;
    height: 100%;

    path.transition {
      transition: fill .1s ease-in-out;
    }
  }
}