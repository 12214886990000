@use '../vars' as v;

.radio {
  display: flex;
  align-items: baseline;
  gap: 10px;
  line-height: 22px;
  cursor: pointer;

  input {
    position: absolute;
    visibility: hidden;
  }

  input + span {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    transition: border-color .3s ease-in-out;
    border: 1px solid #898da9;

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      transition: opacity .3s ease-in-out;
      opacity: 0;
      background-color: v.$violet;
    }
  }

  input:hover + span,
  input:checked + span {
    border-color: #ffffff;

    &::after {
      opacity: 1;
    }
  }
}