@use '../vars' as v;

.team {
  .section-title {
    margin-bottom: 40px;
  }

  &__text {
    margin-bottom: 40px;
    max-width: 875px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      transition: filter .3s ease-in-out;
      filter: saturate(0);
      object-fit: cover;
    }
  }

  &__item:hover,
  &__item.swiper-slide-active {
    img {
      filter: saturate(1);
    }

    .team__item-title,
    .team__item-text {
      opacity: 1;
    }
  }

  &__item-title {
    margin-top: auto;
    font-size: 40px;
    font-weight: 800;
    line-height: 115%;
  }

  &__item-title,
  &__item-text {
    position: relative;
    z-index: 2;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    color: #ffffff;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: background-color .3s ease-in-out;
    color: #ffffff;
    background-color: v.$violet;

    &:hover {
      background-color: v.$light-violet;
    }
  }

  &__link-inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 1050px) {
  .team {
    &__body {
      grid-template-columns: repeat(3, 290px);
    }
  }
}

@media only screen and (max-width: 900px) {
  .team {
    &__body {
      display: flex;
    }

    .swiper {
      margin: 0;
      width: 260px;
      overflow: initial;
    }

    &__item,
    &__link {
      width: 260px;
    }

    &__link {
      height: 350px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .team {
    .swiper {
      width: 245px;
    }

    .section-title {
      margin-bottom: 20px;
    }

    .text-title {
      margin-bottom: 20px;
    }

    &__item,
    &__link {
      width: 245px;
      height: 310px;
    }

    &__item-title {
      font-size: 27px;
    }

    &__link-inner {
      width: 140px;
      height: 140px;

      .text-bold {
        font-size: 15px;
      }
    }
  }
}