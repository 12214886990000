@use '../vars' as v;

.text-title {
  font-size: 24px;
  font-weight: 400;

  span {
    color: v.$violet;
  }
}

@media only screen and (max-width: 1400px) {
  .text-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1060px) {
  .text-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .text-title {
    font-size: 17px;
  }
}