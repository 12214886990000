.tasks {
  .section-title {
    margin-bottom: 50px;
  }

  .text-title {
    margin-bottom: 40px;
    max-width: 850px;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}

@media only screen and (max-width: 1400px) {
  .tasks {
    .section-title {
      margin-bottom: 40px;
    }

    &__body {
      gap: 25px;
    }
  }
}

@media only screen and (max-width: 950px) {
  .tasks {
    .section-title {
      margin-bottom: 20px;
    }

    .text-title {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .tasks {
    &__body {
      grid-template-columns: 1fr;
    }
  }
}