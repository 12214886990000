@use '../vars' as v;

.call-popup {
  width: 100%;
  max-width: 566px;

  .section-title {
    margin-bottom: 35px;
  }

  .text-title {
    margin-bottom: 50px;
    max-width: 566px;
  }

  .input-label {
    svg {
      width: 22px;
      height: 22px;
    }

    &::after {
      background-color: v.$dark-blue;
    }
  }

  .radio-wrapper {
    span {
      max-width: 210px;
    }

    .radio {
      input + span {
        border-color: #aeaeae;
      }

      input:hover + span,
      input:checked + span {
        border-color: v.$dark-blue;
      }
    }

    .checkbox + .checkbox {
      margin-top: 20px;
    }
  }

  .privacy {
    max-width: 341px;
  }
}

@media only screen and (max-width: 500px) {
  .call-popup {
    padding-top: 40px;

    .section-title {
      margin-bottom: 15px;
    }

    .text-title {
      margin-bottom: 25px;
    }

    .form {
      &__inputs-wrapper {
        flex-direction: column;
      }

      &__submit-wrapper {
        flex-direction: column;
        align-items: initial;
      }

      .privacy {
        max-width: initial;
        font-size: 12px;
      }
    }
  }
}