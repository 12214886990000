.popup {
  padding: 15px 35px 15px 15px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  transition: opacity, visibility;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  opacity: 0;
  background-color: #ffffff;

  &.opened {
    visibility: visible;
    opacity: 1;
  }

  &__body {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    height: fit-content;
    //    overflow-x: hidden;
    //    overflow-y: auto;
    max-height: 100%;
    visibility: hidden;
    transition: opacity, visibility, display;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;

    &.opened {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }

  &__close-btn-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    max-width: 1920px;
    height: 100vh;
    max-height: 1080px;
    transform: translateY(-50%) translateX(-50%);

    & ~ * {
      position: relative;
      z-index: 1;
    }
  }

  .close-btn {
    z-index: 30;
    top: 60px;
    right: 100px;
  }
}

@media only screen and (max-width: 1400px) {
  .popup {
    .close-btn {
      top: 30px;
      right: 44px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .popup {
    padding: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .popup {
    .close-btn {
      top: 20px;
      right: 20px;
    }
  }
}