@use '../vars' as v;

.accordion {
  padding-left: 30px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &__num {
    position: absolute;
    top: 7px;
    left: 0;
    color: v.$violet;
  }

  &__title-wrapper {
    padding-bottom: 4px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: v.$violet;
    }

    .text-title {
      line-height: initial;
      transition: color .3s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d6d6d9;
    }
  }

  &__body {
    position: relative;
    transition: max-height;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;

    *:first-child {
      padding-top: 50px;
    }

    *:last-child {
      padding-bottom: 50px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d6d6d9;
    }
  }

  &.initialized {
    .accordion__body {
      max-height: 0;
    }
  }

  &.opened {
    .text-title {
      color: v.$violet;
    }

    .accordion__body {
      //      max-height: 5000px;
    }

    .plus-btn::after {
      transform: rotate(90deg) scale(0);
    }
  }
}

.accordion + .accordion {
  margin-top: 30px;
}