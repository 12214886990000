@use '../vars' as v;

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  transition: background-color .3s ease-in-out;
  border: 1px solid v.$violet;
  border-radius: 50%;

  &:hover {
    background-color: v.$violet;
  }
}

.social-link.type2 {
  flex-direction: column;
  gap: 30px;
  width: initial;
  height: initial;
  border: 0;
  border-radius: 0;

  svg {
    width: 108px;
    height: 108px;
    transition: fill, stroke;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;

    path {
      transition: fill, stroke;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
    }
  }

  span {
    text-decoration: underline;
    color: v.$violet;
  }

  &:hover {
    background-color: transparent;
  }

  &[data-social='vk'] {
    &:hover svg {
      fill: #447cbd;

      path {
        stroke: #447cbd;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .social-link.type2 {
    gap: 15px;

    svg {
      width: 78px;
      height: 78px;
    }
  }
}