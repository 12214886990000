.clients {
  margin: 180px auto 0;
  padding: 70px 0 180px;
  background: #eeeff4;

  .section-title {
    margin-bottom: 40px;
    max-width: 700px;
  }

  &__text {
    margin-bottom: 60px;
    max-width: 520px;
  }

  .swiper {
    overflow: initial;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(5, 230px);
    justify-content: center;
    width: 100%;
    justify-items: center;

    .client-item {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #585858;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .client-item:nth-child(5n+2),
    .client-item:nth-child(5n+3),
    .client-item:nth-child(5n+4),
    .client-item:nth-child(5n+5) {
      &::before {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .clients {
    &__body {
      grid-template-columns: repeat(5, 210px);
    }
  }
}

@media only screen and (max-width: 1080px) {
  .clients {
    &__body {
      grid-template-columns: repeat(4, 230px);

      .client-item:nth-child(5n+2),
      .client-item:nth-child(5n+3),
      .client-item:nth-child(5n+4),
      .client-item:nth-child(5n+5) {
        &::before {
          display: initial;
        }
      }

      .client-item:nth-child(4n+2),
      .client-item:nth-child(4n+3),
      .client-item:nth-child(4n+4), {
        &::before {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .clients {
    margin: 120px auto 0;
    padding: 40px 0 120px;
  }
}

@media only screen and (max-width: 950px) {
  .clients {
    &__body {
      grid-template-columns: repeat(4, 210px);
    }
  }
}

@media only screen and (max-width: 900px) {
  .clients {
    margin: 90px auto 0;
    padding: 40px 0 90px;

    .section-title {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__body {
      display: flex;
      justify-content: initial;

      .client-item:nth-child(4n+2),
      .client-item:nth-child(4n+3),
      .client-item:nth-child(4n+4), {
        &::before {
          display: initial;
        }
      }

      .client-item::before {
        display: none !important;
      }

      .client-item:first-child::before {
        display: initial !important;
      }
    }
  }
}
