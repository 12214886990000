@use '../vars' as v;

.footer {
  color: #ffffff;
  background-color: v.$dark-blue;

  .container {
    margin-bottom: 135px;
  }

  &__body {
    padding-top: 140px;
    padding-bottom: 170px;
    position: relative;
    display: flex;
    gap: 45px;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d6d6d9;
    }
  }

  &__form-block {
    max-width: 586px;

    .section-title {
      margin-bottom: 97px;
    }
  }

  .form {
    .input-label {
      align-items: initial;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__inputs {
      width: 100%;
      max-width: 240px;
    }

    &__text {
      padding-bottom: 30px;
    }
  }

  &__photo-block {
    max-width: 470px;

    img {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      max-width: 470px;
      height: 300px;
      object-fit: cover;
    }

    .text-title {
      margin-bottom: 20px;
    }
  }

  &__photo-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: italic;
    line-height: 1.5;
  }

  &__under {
    padding-top: 60px;
    display: flex;
    gap: 30px 45px;
  }

  &__logo-block {
    .logo {
      margin-bottom: 40px;
    }
  }

  &__socials {
    margin: 40px 0 20px;
  }

  &__socials + * {
    margin-bottom: 40px;
  }

  &__menu-block {
    margin-right: auto;
    margin-left: 95px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__contacts-block {
    padding-right: 20px;

    .text-title {
      margin-bottom: 42px;
    }

    .tel-link + .tel-link {
      margin-top: 12px;
    }

    .text-btn.type2 {
      margin: 10px 0 20px;
    }

    .dot-underline {
      margin-bottom: 4px;
      width: fit-content;

      &:hover {
        color: v.$violet;
      }

      &::after {
        background-image: url('data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' stroke=\'white\' stroke-width=\'1\' stroke-dasharray=\'2%2c 4\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e');
      }
    }
  }

  .footer__address span {
    line-height: 1.8;
  }
}

@media only screen and (max-width: 1400px) {
  .footer {
    .container {
      margin-bottom: 65px;
    }

    &__body {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &__form-block {
      .section-title {
        margin-bottom: 45px;
      }
    }
  }
}

@media only screen and (max-width: 1060px) {
  .footer {
    .form {
      &__submit-wrapper {
        flex-direction: column;
        align-items: initial;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .footer {
    .form {
      &__inputs {
        max-width: initial;
      }

      &__inputs-wrapper {
        flex-direction: column;
      }
    }

    &__menu-block {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .footer {
    &__form-block {
      flex-shrink: 0;
      max-width: 285px;
    }
  }
}

@media only screen and (max-width: 670px) {
  .footer {
    &__body {
      flex-direction: column;
    }

    &__form-block {
      max-width: initial;

      .section-title {
        max-width: 430px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    .container {
      margin-bottom: 45px;
    }

    &__form-block {
      .section-title {
        margin-bottom: 15px;
      }
    }

    &__body {
      padding-top: 45px;
      padding-bottom: 30px;
    }

    &__under {
      padding-top: 30px;
      flex-wrap: wrap;
    }

    &__logo-block .logo {
      margin-bottom: 10px;
    }

    &__socials {
      margin: 20px 0;
    }

    &__socials + * {
      margin-bottom: 15px;
    }

    &__menu-block {
      margin-right: 40px;
      margin-left: auto;
    }

    &__contacts-block {
      .text-title {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .footer {
    &__under {
      flex-direction: column;
    }

    &__menu-block {
      margin: 0;
    }
  }
}