.plus-btn {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    transition: transform .3s ease-in-out;
    background-color: #000000;
  }

  &::after {
    transform: rotate(90deg);
  }
}