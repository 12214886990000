@use '../vars' as v;

.text-btn {
  transition: text-decoration-color .2s ease-in-out;
  text-decoration: underline;
  color: v.$violet;

  &:hover {
    text-decoration-color: transparent;
  }
}

.text-btn.type2 {
  color: v.$light-violet;

  &:hover {
    color: v.$violet;
  }
}