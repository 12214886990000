@use '../vars' as v;

.contact-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  border: 1px solid #ffffff;
  background-color: v.$violet;
}