@use '../vars' as v;

.section-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.17;

  span {
    color: v.$violet;
  }
}

@media only screen and (max-width: 1060px) {
  .section-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 800px) {
  .section-title {
    font-size: 24px;
  }
}