@use '../vars' as v;

.project-popup {
  padding: 0 15px;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  overflow: hidden;
  --max-width: calc(100vw - 100px - 110px);
  --padding: 0 245px;
  --title-font-size: 32px;
  --title-margin-bottom: 50px;
  --title-height: calc(var(--title-font-size) * 2 + (var(--title-font-size) * 2 * 15 / 100));

  &__title {
    margin: 0 auto var(--title-margin-bottom);
    padding: var(--padding);
    z-index: -1;
    max-width: var(--max-width);
    font-size: var(--title-font-size);
    font-weight: 800;
    line-height: 115%;

    span {
      color: v.$violet;
    }
  }

  &__body-wrapper {
    margin: 0 auto;
    padding: var(--padding);
    display: flex;
    gap: 45px;
    justify-content: space-between;
    max-width: var(--max-width);
    height: calc(100% - var(--title-height) - var(--title-margin-bottom));
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #b3b8e3 #ffffff;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b3b8e3;
    }

    &::-webkit-scrollbar-track {
      margin-top: 50px;
      border: 2px solid #d6d6d9;
      background: transparent;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 702px;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 348px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(-7px 9px 20px rgba(17, 28, 69, 0.16));
    }

    .ul li::before {
      background-color: #000000;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .project-popup {
    --max-width: calc(1920px - 100px - 110px);
  }
}

@media only screen and (max-width: 1700px) {
  .project-popup {
    --padding: 0 125px;
  }
}

@media only screen and (max-width: 1400px) {
  .project-popup {
    --padding: 0 85px;
    --max-width: calc(100vw - 100px);
  }
}

@media only screen and (max-width: 1150px) {
  .project-popup {
    --padding: 0 15px;

    &__title {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .project-popup {
    --pading: 0;
    --title-margin-bottom: 30px;

    &__title {
      margin: 0 0 var(--title-margin-bottom);
    }

    &__body-wrapper {
      margin: 0;
      height: calc(100% - var(--title-height) - 50px - var(--title-margin-bottom));
    }

    .titled-block {
      padding-left: 0;

      &__title-wrapper {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          position: initial;
        }

        .text-bold {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .project-popup {
    --title-font-size: 25px;

    &__title {
      padding: 0 18px 0 0;
      max-width: initial;
    }

    &__body-wrapper {
      padding: 0;
      flex-direction: column;
      max-width: initial;
    }

    &__info-block {
      gap: 25px;
      max-width: initial;

      img {
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .project-popup {
    --title-font-size: 20px;
  }
}