// Underground
@font-face {
  font-family: 'Underground';
  src: url('../fonts/P22UndergroundCYPro-Book.woff2') format('woff2'),
  url('../fonts/P22UndergroundCYPro-Book.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Underground';
  src: url('../fonts/P22UndergroundCYPro-Medium.woff2') format('woff2'),
  url('../fonts/P22UndergroundCYPro-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Underground';
  src: url('../fonts/P22UndergroundCYPro-Heavy.woff2') format('woff2'),
  url('../fonts/P22UndergroundCYPro-Heavy.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}

// Roboto
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}