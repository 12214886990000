@use '../vars' as v;

.form {
  &__inputs-wrapper {
    margin-bottom: 30px;
    display: flex;
    gap: 48px;
  }

  &__inputs {
    padding-top: 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
  }

  &__text {
    max-width: 475px;
    font-size: 14px;
    font-weight: 400;
  }

  &__submit-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 15px 26px;
  }

  &__error-text {
    margin-top: 10px;
    padding: 20px;
    position: absolute;
    top: 0;
    width: fit-content;
    font-weight: 400;
    visibility: hidden;
    transition: opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    opacity: 0;
    color: #ffffff;
    background-color: v.$violet;

    & + * {
      padding-top: 8px;
    }
  }

  &.not-valid {
    .form__error-text {
      position: initial;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .form {
    &__inputs-wrapper {
      gap: 25px 15px;
    }
  }
}