.radio-wrapper {
  padding-left: 31px;
  position: relative;
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: 10px;
  }

  svg {
    position: absolute;
    top: 3px;
    left: 0;
  }

  .radio + .radio {
    margin-top: 12px;
  }
}