@use '../vars' as v;

.ul {
  display: flex;
  flex-direction: column;
  gap: 26px;

  li {
    padding-left: 23px;
    position: relative;
    font-weight: 400;
    line-height: 22px;

    &::before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      width: 13px;
      height: 1px;
      background-color: v.$violet;
    }

    span {
      font-weight: 800;
    }
  }
}