.link-btn {
  padding-right: 11px;
  position: relative;
  width: fit-content;
  line-height: 18px;
  text-decoration: underline;

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    width: 6px;
    height: 6px;
    background-image: url('../images/static/icons/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

@media only screen and (max-width: 760px) {
  .link-btn {
    padding-right: 9px;
    font-size: 12px;
  }
}