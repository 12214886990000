@use '../vars' as v;

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 36px;
      transition: transform .3s ease-in-out;
      background-color: v.$violet;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &:hover {
    span {
      &::before {
        transform: rotate(45deg) scaleY(0.7);
      }

      &::after {
        transform: rotate(-45deg) scaleY(0.7);
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .close-btn {
    span::before,
    span::after {
      height: 29px;
    }
  }
}