@use '../vars' as v;

.home-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;

  svg path {
    transition: stroke .2s ease-in-out;
  }

  &:hover svg path {
    stroke: v.$dark-blue;
  }
}