.skills {
  &__body-wrapper {
    display: flex;
    gap: 45px;
    justify-content: space-between;
  }

  &__img-block {
    max-width: 443px;

    img {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      max-width: 443px;
      height: 100%;
      max-height: 703px;
      object-fit: cover;
    }

    .text-bold {
      margin-bottom: 14px;
    }
  }

  &__img-text {
    max-width: 300px;
  }

  &__body {
    max-width: 586px;

    .section-title {
      margin-bottom: 40px;
    }
  }

  .titled-block__text {
    color: #acaeb7;

    .ul {
      li::before {
        background-color: #acaeb7;
      }
    }
  }

  &__text {
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 46px;
  }
}

.skills + * {
  margin-top: 190px;
}

@media only screen and (max-width: 1400px) {
  .skills {
    &__img-block {
      margin-left: 50px;

      img {
        max-height: 550px;
      }
    }

    &__body {
      margin-right: 50px;

      .section-title {
        margin-bottom: 25px;
      }
    }

    &__text {
      margin-bottom: 25px;
    }

    &__list {
      gap: 35px;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .skills {
    &__img-block {
      margin-left: 0;
      flex-shrink: 0;
      max-width: 315px;
    }

    &__body {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .skills + * {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 800px) {
  .skills {
    .section-title {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 760px) {
  .skills {
    &__body-wrapper {
      flex-direction: column;
    }

    &__img-block {
      img {
        margin-bottom: 15px;
      }

      .text-bold {
        margin-bottom: 7px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .skills + * {
    margin-top: 90px;
  }
}