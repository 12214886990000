.city-popup {
  width: 100%;
  max-width: 706px;

  .section-title {
    margin-bottom: 50px;
    z-index: -1;
    font-weight: 400;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 14px 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .city-popup {
    .section-title {
      margin-bottom: 20px;
    }

    &__body {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (max-width: 500px) {
  .city-popup {
    &__body {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}