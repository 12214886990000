@use '../vars' as v;

.thx-popup {
  width: 100%;
  max-width: 620px;
  text-align: center;

  .section-title {
    margin-bottom: 30px;
    font-weight: 400;
  }

  &__text {
    margin-bottom: 40px;
    font-weight: 400;
  }

  &__cta-text {
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: 800;
    line-height: 122%;
    color: v.$violet;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .thx-popup {
    .section-title {
      margin-bottom: 15px;
    }

    &__text {
      margin-bottom: 15px;
    }

    &__cta-text {
      margin: 0 auto 15px;
      max-width: 320px;
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .thx-popup {
    &__cta-text {
      margin-bottom: 5px;
      max-width: 250px;
      font-size: 23px;
    }
  }
}