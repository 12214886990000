.logo {
  width: 167px;
  height: 48px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media only screen and (max-width: 500px) {
  .logo {
    width: 140px;
  }
}