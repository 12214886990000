@use '../vars' as v;

.menu-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27px;
  height: 17px;

  span {
    width: 100%;
    height: 3px;
    transition: transform, background-color;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    border-radius: 4px;
    background-color: v.$dark-blue;

    &:nth-child(1),
    &:nth-child(3) {
      transform-origin: 0;
    }
  }

  &.opened {
    height: 22px;

    span {
      background-color: v.$violet;
    }

    span:nth-child(1) {
      width: 27px;
      transform: translateX(5px) rotate(45deg);
    }

    span:nth-child(2) {
      transform: scaleX(0);
    }

    span:nth-child(3) {
      width: 27px;
      transform: translateX(5px) rotate(-45deg);
    }
  }
}