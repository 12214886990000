.input-label {
  padding-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  height: 36px;

  input {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
  }
}