@use '../vars' as v;

.h1 {
  font-size: 80px;
  font-weight: 800;
  line-height: 100%;

  span {
    color: v.$violet;
  }
}

@media only screen and (max-width: 1400px) {
  .h1 {
    font-size: 65px;
  }
}

@media only screen and (max-width: 1060px) {
  .h1 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 800px) {
  .h1 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 580px) {
  .h1 {
    font-size: 30px;
  }
}