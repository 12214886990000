@use '../vars' as v;

.checkbox {
  display: flex;
  gap: 10px;
  cursor: pointer;
  color: #aeaeae;

  input {
    position: absolute;
    visibility: hidden;
  }

  span {
    transition: color .3s ease-in-out;
  }

  input + span {
    position: relative;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    transition: border-color .3s ease-in-out;
    border: 1px solid #aeaeae;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      transition: opacity .3s ease-in-out;
      opacity: 0;
      background-color: #000000;
    }

    &::before {
      top: 5px;
      left: 3.9px;
      height: 5px;
      transform: rotate(-52deg);
    }

    &::after {
      top: 3px;
      right: 3.1px;
      height: 7px;
      transform: rotate(43deg);
    }
  }

  input:checked + span {
    border-color: #1c1f33;

    &::before,
    &::after {
      opacity: 1;
    }
  }

  input:checked ~ span {
    color: v.$dark-blue;
  }
}