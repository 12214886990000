@use '../vars';

.header {
  position: relative;
  border: solid #eeeff4;
  border-width: 0 0 1px 0;
  background-color: #ffffff;

  .container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: 30px;
  }

  .home-btn {
    margin-right: 30px;
  }

  &__menu {
    ul {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    li {
      cursor: pointer;
      transition: color .2s ease-in-out;

      &:hover {
        color: vars.$violet;
      }
    }
  }

  &__menu &__tel-list {
    margin-top: 16px;
    margin-right: 0;
    display: none;
  }

  & &__menu .geo-btn {
    margin: 15px 0 0;
    display: none;
  }

  &__menu .call-btn {
    margin-top: 15px;
    display: none;
  }

  &__tel-list {
    margin-right: 10px;
    margin-left: auto;
    font-size: 22px;
    font-weight: 400;
  }

  .menu-btn {
    margin-left: 20px;
    display: none;
  }
}

@media only screen and (max-width: 1135px) {
  .header {
    &__menu {
      padding: 15px;
      position: absolute;
      z-index: 5;
      top: calc(100% + 1px);
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      height: 100vh;
      visibility: hidden;
      transition: visibility, opacity;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      opacity: 0;
      background-color: #ffffff;

      &.opened {
        visibility: visible;
        opacity: 1;
      }

      ul {
        flex-direction: column;
      }
    }

    .menu-btn {
      display: flex;
    }
  }
}

@media only screen and (max-width: 730px) {
  .header {
    &__tel-list {
      margin-left: 0;
      display: none;
    }

    .container > .call-btn {
      margin-left: auto;
    }

    &__menu &__tel-list {
      display: block;
    }
  }
}

@media only screen and (max-width: 565px) {
  .header {
    & &__menu .geo-btn {
      display: block;
    }

    .call-btn {
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: 450px) {
  .header {
    .call-btn {
      margin-left: 0;
      display: none;
    }

    &__menu .call-btn {
      display: block;
    }

    .menu-btn {
      margin-left: auto;
    }
  }
}