.hero {
  position: relative;
  height: 773px;

  .container {
    padding-top: 200px;
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  &__inner {
    padding: 15px;
    background-color: #ffffff;
  }

  .h1 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .hero {
    height: 640px;

    .container {
      padding-top: 140px;
    }

    .h1 {
      max-width: 970px;
    }

    .subtitle {
      max-width: 950px;
    }
  }
}

@media only screen and (max-width: 1060px) {
  .hero {
    height: 520px;

    .container {
      padding-top: 120px;
    }

    .h1 {
      max-width: 770px;
    }

    .subtitle {
      max-width: 750px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .hero {
    height: 420px;

    .container {
      padding-top: 105px;
    }

    .h1 {
      max-width: 530px;
    }

    .subtitle {
      max-width: 570px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .hero {
    height: initial;

    .container {
      padding-top: 65px;
      padding-bottom: 65px;
    }

    .h1 {
      max-width: 420px;
    }
  }
}