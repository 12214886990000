.faq {
  .section-title {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .faq {
    .section-title {
      margin-bottom: 20px;
    }
  }
}